<template lang="pug">
.fixed-bottom-right.chat-buttons
    .row.justify-center.items-center.text-weight-bold.roboto.font-10.absolute-top-right.new-messages(
        v-if="newMessages") {{ newMessages }}
    q-fab.pulse(
        ref="fab"
        color="positive"
        direction="up"
        icon="etabl:phone_white"
        @show="showEvent"
        :style="showChat")
        q-fab-action(
            external-label
            type="a"
            label-position="left"
            label-class="bg-secondary text-primary"
            color="secondary"
            icon="etabl:phone_chat"
            label="Позвонить"
            @click.prevent="goCall"
            text-color="positive")
        q-fab-action(
            external-label
            label-position="left"
            label-class="bg-secondary text-primary"
            color="secondary"
            icon="etabl:dialog_chat"
            label="Написать"
            text-color="positive"
            @click="goChat")
            .row.justify-center.items-center.text-weight-bold.roboto.font-10.new-messages(
                v-if="newMessages") {{ newMessages }}
        q-fab-action(
            external-label
            label-position="left"
            label-class="bg-secondary text-primary"
            color="secondary"
            icon="etabl:cloud_chat"
            label="Вопрос/Ответ"
            text-color="positive"
            @click="goToPage('/faq')")
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import md5 from 'src/assets/helpers/md5.js';
import { convertGender } from 'src/assets/helpers';


export default {
    name: 'FloatBtn',
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const fab = ref();
        const isActive = ref(false);
        const showChat = ref(false);
        const newMessages = ref(0);
        let initChat = false;
        const chatLoad = ref(false);

        // данные пользователя
        const userInfo = computed(() => store.getters['user/USER_INFO']);

        // E-mail пользователя
        const userEmail = computed(() => store.getters['user/USER_EMAIL']);

        // Телефона пользователя
        const userPhone = computed(() => store.getters['user/USER_PHONE']);

        // uid пользователя
        const userUid = computed(() => store.getters['user/USER_UID']);

        // id пользователя
        const userId = computed(() => store.getters['user/USER_ID']);

        watch(() => route, () => {
            if (isActive.value) {
                isActive.value = false;
            }
        });

        const hide = () => {
            fab.value.hide();
        };

        const showEvent = async () => {
            if (newMessages.value) {
                showChat.value = true;
                newMessages.value = 0;
            }
        };

        const preventFabHide = () => {
            fab.value.show();
        };

        const getIp = async () => {
            return new Promise((resolve) => {
                fetch('https://httpbin.org/ip')
                    .then((response) => {
                        resolve(response.json());
                    })
                    .catch(() => {
                        resolve({
                            origin: 'Не удалось определить...',
                        });
                    });
            });
        };

        const goChat = async () => {
            const user_data = {
                name: userInfo.value?.name || 'Незарегистрированный пользователь',
                patronymic: userInfo.value?.patronymic || null,
                gender: userInfo.value?.gender || null,
                email: userEmail.value?.email_address || null,
                phone: userPhone.value?.phone_number || null,
            };

            if (Object.keys(window.widget.userRegisterData).length === 0) {
                const { origin: ip } = await getIp();

                if (!userUid.value) {
                    await store.dispatch('user/SET_UID');
                }

                window.widget.setUserRegisterData({
                    'hash': md5(userUid.value).toLowerCase(),
                    'name': user_data.name,
                    'lastName': user_data.patronymic,
                    'gender': user_data.gender,
                    'email':user_data.email,
                });

                window.widget.setCustomData(`
                    ID пользователя: ${userUid.value}
                    IP пользователя: ${ip}
                    Имя: ${user_data.name || ''}
                    Отчество: ${user_data.patronymic || ''}
                    Пол: ${user_data.gender ? convertGender(user_data.gender) : ''}
                    Номер телефона: ${user_data.phone || ''}
                    E-mail: ${user_data.email || ''}
                `);
            }

            if (userId.value && userId.value !== userUid.value) {
                store.commit('user/SET_UID', userId.value);

                window.widget.setUserRegisterData({
                    'hash': md5(userUid.value).toLowerCase(),
                    'name': user_data.name,
                    'lastName': user_data.patronymic,
                    'gender': user_data.gender,
                    'email':user_data.email,
                });
            }

            window.widget.open();
        };

        const closeChat = () => {
            hide();
            setTimeout(() => showChat.value = false, 500);
        };

        const newMessage = () => {
            if (!showChat.value) {
                newMessages.value++;
            }
        };

        const goToPage = (page) => {
            router.push(page);
        };

        const goCall = () => {
            if (process.env.CLIENT) {
                window.location.replace('tel:+78002005400');
            }
        };

        return {
            fab,
            goChat,
            goCall,
            showChat,
            isActive,
            goToPage,
            showEvent,
            closeChat,
            newMessages,
            chatLoad,
        };
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    & .overlay {
        position: fixed;
        background: rgba(0, 0, 0, 0.4);
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease-in-out;
    }

    &.active .overlay {
        opacity: 1;
        visibility: visible;
    }

    &.active .q-fab {
        opacity: 1;
    }

    .q-fab {
        opacity: 0.6;
        transition: opacity 0.2s ease-in-out;
        background: red;


        .q-fab__label {
            height: 40px;
            border-radius: 27px;
            padding: 8px 22px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
            font-size: 1.1em;
            color: black !important;

            &:nth-of-type(2) {
                padding-left: 35px;
                padding-right: 35px;
            }
        }

        .q-btn__wrapper {
            padding: 0;

            .q-fab__icon-holder {
                min-width: 32px;
                min-height: 32px;

                img,
                i {
                    font-size: 32px;
                }
            }
        }
    }
}

:deep(.q-fab__actions-chat) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.chat-buttons {
    z-index: 5100;
    bottom: 30px;
    right: 30px;

    @media screen and (max-width: $breakpoint-mobile) {
        bottom: 128px;
        right: 10px;
    }
}

.new-messages {
    width: 20px;
    height: 20px;
    z-index: 4000;
    background-color: $negative;
    border-radius: 10px;
    color: $secondary;
    line-height: 0;
}

.pulse {
    display: block;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    cursor: pointer;
    animation: pulse 2s infinite;
}
.pulse:hover {
    animation: none;
}

.q-fab--opened {
    animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(34, 189, 119, 1);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(234, 189, 119, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(34, 189, 119, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(34, 189, 119, 1);
    box-shadow: 0 0 0 0 rgba(34, 189, 119, 1);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(34, 189, 119, 0);
      box-shadow: 0 0 0 10px rgba(34, 189, 119, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(34, 189, 119, 0);
      box-shadow: 0 0 0 0 rgba(34, 189, 119, 0);
  }
}
</style>
